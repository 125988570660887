import {
  Link,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from '@remix-run/react';

import {
  EnumsMessageDistributionChannel,
  EnumsMessageLogicStatus,
  EnumsMessageTemplateFormat,
} from '@lp-lib/api-service-client/public';

import { type Action, ActionSheet } from '../components/ActionSheet';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../components/ConfirmCancelModalContext';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { EditIcon } from '../components/icons/EditIcon';
import { EyeIcon } from '../components/icons/EyeIcon';
import { type MessageCampaignV2 } from '../components/Message/Shared';
import {
  type MessageTemplateSettings,
  MessageTemplateSettingsUtils,
} from '../components/Message/TemplateSettings';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';

export async function clientLoader() {
  const templateSettingsList =
    await MessageTemplateSettingsUtils.GetAllMessageTemplateSettings();
  const campaignList = (await apiService.message.queryMessageCampaigns()).data
    .campaigns;

  const campaignSummaryList = await Promise.all(
    campaignList.map(async (campaign) => {
      const logics = (
        await apiService.message.getLogicsByCampaign(campaign.type, campaign.id)
      ).data.logics;
      const activeCount = logics.filter(
        (logic) =>
          logic.status === EnumsMessageLogicStatus.MessageLogicStatusActive
      ).length;
      const totalCount = logics.length;
      return {
        active: activeCount,
        total: totalCount,
      };
    })
  );

  return {
    templateSettingsList,
    campaignList,
    campaignSummaryList,
  };
}

function MessageCampaignsTable(props: {
  campaigns: MessageCampaignV2[];
  campaignSummaryList: { active: number; total: number }[];
}) {
  const { campaigns, campaignSummaryList } = props;

  const navigate = useNavigate();

  return (
    <div className='w-full'>
      <header className='text-2xl font-bold'>
        Message Campaigns{' '}
        <span className='text-icon-gray text-xl'>({campaigns.length})</span>
      </header>
      <table className='mt-4 w-full table-fixed border-collapse'>
        <thead>
          <tr>
            <th className='text-left font-bold pl-3 py-2 border-b border-t border-secondary'>
              Name
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary'>
              Trigger(T0)
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary'>
              Logics (Active / Total)
            </th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((campaign, index) => (
            <tr
              key={campaign.id}
              className='hover:bg-dark-gray cursor-pointer h-full'
              onClick={() =>
                navigate(`/admin/messages/campaigns/${campaign.id}`)
              }
            >
              <td className='text-left font-bold pl-3 py-3 border-b border-black-001 pr-3 truncate'>
                {campaign.name}
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 truncate whitespace-pre-line'>
                {campaign.description}
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 truncate'>
                {`${campaignSummaryList[index].active} / ${campaignSummaryList[index].total}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function MessageTemplateMenu(props: {
  settings: MessageTemplateSettings;
  onPreview: () => void;
  onEdit: () => void;
  onDelete: () => void;
}) {
  const { settings, onPreview, onEdit, onDelete } = props;

  const actions: Action<string>[] = [];
  if (
    settings.format ===
    EnumsMessageTemplateFormat.MessageTemplateFormatHardcoded
  ) {
    actions.push({
      kind: 'button',
      key: 'preview',
      text: 'Preview',
      icon: <EyeIcon />,
      onClick: onPreview,
    });
  } else {
    actions.push({
      kind: 'button',
      key: 'edit',
      text: 'Edit',
      icon: <EditIcon />,
      onClick: onEdit,
    });
    actions.push({
      kind: 'button',
      key: 'delete',
      text: 'Delete',
      icon: <DeleteIcon />,
      onClick: onDelete,
    });
  }

  return <ActionSheet btnSizingClassName='w-7.5 h-7.5' actions={actions} />;
}

function MessageTemplatesTable(props: {
  templateSettingsList: MessageTemplateSettings[];
}) {
  const { templateSettingsList } = props;

  const navigate = useNavigate();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const revalidator = useRevalidator();

  const handleEdit = async (settings: MessageTemplateSettings) => {
    if (
      settings.format ===
        EnumsMessageTemplateFormat.MessageTemplateFormatHardcoded &&
      settings.distributionChannel ===
        EnumsMessageDistributionChannel.MessageDistributionChannelSlack
    ) {
      MessageTemplateSettingsUtils.OpenSlackBlocksEditor(settings.key);
      return;
    }

    navigate(`/admin/messages/templates/${settings.id}`);
  };

  const handleDelete = async (settings: MessageTemplateSettings) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Delete</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Are you sure you want to delete this template? This action cannot be
            undone.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await apiService.message.deleteTemplateSettings(settings.id);
    revalidator.revalidate();
  };

  return (
    <div className='w-full'>
      <header className='flex justify-between items-center'>
        <h2 className='text-2xl font-bold'>
          Message Templates{' '}
          <span className='text-icon-gray text-xl'>
            ({templateSettingsList.length})
          </span>
        </h2>
        <Link
          className='btn flex justify-center items-center text-primary gap-1 text-sm font-medium hover:underline'
          to={'/admin/messages/templates/create'}
        >
          + Add New Template
        </Link>
      </header>
      <table className='mt-4 w-full table-fixed border-collapse'>
        <thead>
          <tr>
            <th className='text-left font-bold pl-3 py-2 border-b border-t border-secondary w-80'>
              Name
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary w-36'>
              Send Via
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary w-36'>
              Format
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary'>
              Description
            </th>
            <th className='text-left font-bold py-2 border-b border-t border-secondary w-12' />
          </tr>
        </thead>
        <tbody>
          {templateSettingsList.map((settings) => (
            <tr
              key={settings.id}
              className='hover:bg-dark-gray cursor-pointer h-full'
              onClick={() => handleEdit(settings)}
            >
              <td className='text-left font-bold pl-3 py-3 border-b border-black-001 pr-3'>
                {settings.name}
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 capitalize'>
                {settings.distributionChannel}
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 capitalize'>
                {settings.format}
              </td>
              <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3'>
                <p className='line-clamp-3 whitespace-pre-line'>
                  {settings.description || ''}
                </p>
              </td>
              <td className='h-px p-0 border-b border-black-001'>
                <div className='h-full flex items-center justify-center'>
                  <MessageTemplateMenu
                    settings={settings}
                    onPreview={() => handleEdit(settings)}
                    onEdit={() => handleEdit(settings)}
                    onDelete={() => handleDelete(settings)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Component() {
  const { campaignList, campaignSummaryList, templateSettingsList } =
    useLoaderData<typeof clientLoader>();

  return (
    <AdminView className='text-white w-full h-full p-10'>
      <AdminToolkitNav />

      <h1 className='mt-10 text-4xl font-bold'>Messages Library</h1>

      <div className='mt-15 flex flex-col gap-10'>
        <MessageCampaignsTable
          campaigns={campaignList}
          campaignSummaryList={campaignSummaryList}
        />
        <MessageTemplatesTable templateSettingsList={templateSettingsList} />
      </div>
    </AdminView>
  );
}
